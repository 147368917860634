import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FeatureToggles,
  RolloutFlags,
  SolutionFeatures,
} from 'core/api/types/features';
import { config } from 'core/config';
import type { RootState } from 'state/store';

/**
 * Feature toggles
 */

export const featureToggleNames = [
  // Add feature toggles in this array
  'accountsViewCompanyCreditAccounts',
  'accountsViewCompanyPrepaidAccounts',
  'cardsActCompanyCardSpendingRules',
  'cardsActCompanyCreateCard',
  'cardsActCompanyFreezeCard',
  'cardsActCompanyTerminateCardWithReason',
  'cardsTerminateCompanyCards',
  'cardsTerminateOwnCards',
  'cardsViewCompanyCards',
  'cardsViewCompanyCardTerminationWithReplacement',
  'cardsViewCompanyOrderedCards',
  'expensesViewCompanyExpenses',
  'exportsViewCompanyExports',
  'peopleActCompanyInvitations',
  'peopleUpdateAccountingInformation',
  'peopleViewCompanyEmployees',
  'requestsActCompanyRequests',
  'requestsActOwnRequests',
  'requestsViewCompanyRequests',
  'settingsViewCompanyBalanceAccounts',
  'settingsViewCompanyCategories',
  'settingsViewCompanyDocuments',
  'settingsActCompanyCostDimensions',
  'statementsViewCompanyStatements',
  'teamsActCompanyTeams',
  'transactionsViewCompanyTransactions',
  'mileagesActCompanyMileages',
  'downloadsViewCompanyDownloads',
  'accountsViewCompanyAccountPayout',
  'accountsActCompanyAccountPayout',
  'peopleViewEmployeeId',
  'cardsActCompanyCardUpdatePayer',
  'cardsViewCompanyCardPayer',
  'expensesActAdditionalTextProperty',
  'mileagesActAdditionalTextProperty',
  'peopleViewCompanyTaxDetails',
  'cardProgramsViewCompanyCardPrograms',
  'cardProgramsActCompanyCardPrograms',
  'peopleActCompanyEmail',
] as const;

export type FeatureToggleName = (typeof featureToggleNames)[number];

export type FeatureToggleState = { [flag in FeatureToggleName]: boolean };

/**
 * Rollout flags
 */

export const rolloutFlagNames = [
  // Add rollout flags in this array
  'web.show.test.page', // Do not delete this demo rollout flag, we use it for unit testing
  'web.people.invitation-data', // Clean up ticket https://cardlay.atlassian.net/browse/CPD-15768
  'web.mileage.show-mileage-page', // Cleanup ticket https://cardlay.atlassian.net/browse/CPD-17543
  'web.accounts.company-account-payout', // Cleanup ticket https://cardlay.atlassian.net/browse/CPD-18346
  'web.people-details.show.cards-tab', // Cleanup ticket https://cardlay.atlassian.net/browse/CPD-18227
  'global.multiple-card-forms', // Cleanup ticket https://cardlay.atlassian.net/browse/CPD-19690
  'global.expenses.export-datev', // Cleanup ticket https://cardlay.atlassian.net/browse/CPD-19890
  'web.people.show.delete-invitation', // Cleanup ticket https://cardlay.atlassian.net/browse/CPD-20377
  'web.home.show-changelog', // Cleanup ticket https://cardlay.atlassian.net/browse/CPD-20447
  'global.card-programs', // Cleanup ticket: https://cardlay.atlassian.net/browse/CPD-20655
  'web.card-programs-bulk-apply', // Cleanup ticket: https://cardlay.atlassian.net/browse/CPD-21811
  'web.people.employees-pagination', // Cleanup ticket: https://cardlay.atlassian.net/browse/CPD-21742
  'web.global.use-new-bulk-actions', // Cleanup ticket https://cardlay.atlassian.net/browse/CPD-21847
  'global.expenses.use.split.types', // Cleanup ticket: https://cardlay.atlassian.net/browse/CPD-21894
  'global.onboarding.split-invitation-flow', // Cleanup ticket: https://cardlay.atlassian.net/browse/CPD-22169
  'web.user.profile.show-release-notes', // Cleanup ticket: https://cardlay.atlassian.net/browse/CPD-22343
  'web.people.use-tab-bar', // Cleanup ticket: https://cardlay.atlassian.net/browse/CPD-22364
] as const;

export type RolloutFlagName = (typeof rolloutFlagNames)[number];

export type RolloutFlagState = { [flag in RolloutFlagName]: boolean };

/**
 * Solution feature toggles
 */
export const solutionFeatureNames = [
  'showLoginForm',
  'showSetPassword',
  'showForgotPassword',
  'showSignUpDetailsForm',
  'showDownloadApps',
  'showLoginFooterLogo',
  'deviceMonitoring',
  'termsAndConditionsConfirmationRequired',
] as const;

export type SolutionFeatureName = (typeof solutionFeatureNames)[number];

type SolutionFeatureState = { [flag in SolutionFeatureName]: boolean };

/**
 * Initial state
 */

export type FeaturesState = {
  featureToggles: FeatureToggleState;
  rolloutFlags: RolloutFlagState;
  solutionFeatures: SolutionFeatureState;
};

export const initialState: FeaturesState = {
  featureToggles: featureToggleNames.reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {} as FeatureToggleState
  ),
  rolloutFlags: rolloutFlagNames.reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {} as RolloutFlagState
  ),
  solutionFeatures: solutionFeatureNames.reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {} as SolutionFeatureState
  ),
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatureToggles: (
      state,
      { payload }: PayloadAction<{ featureToggles: Partial<FeatureToggles> }>
    ) => {
      Object.entries(payload.featureToggles).forEach(
        ([toggleName, toggleValue]) => {
          // Only set the toggle value if it is a known toggle in the state
          if (
            Object.keys(state.featureToggles).includes(toggleName) ||
            config.environment === 'development'
          ) {
            state.featureToggles[toggleName as FeatureToggleName] = toggleValue;
          }
        }
      );
    },
    setRolloutFlags: (
      state,
      { payload }: PayloadAction<{ rolloutFlags: Partial<RolloutFlags> }>
    ) => {
      Object.entries(payload.rolloutFlags).forEach(([flagName, flagValue]) => {
        // Only set the flag value if it is a known flag in the state
        if (
          Object.keys(state.rolloutFlags).includes(flagName) ||
          config.environment === 'development'
        ) {
          state.rolloutFlags[flagName as RolloutFlagName] = flagValue;
        }
      });
    },
    setSolutionFeatures: (
      state,
      {
        payload,
      }: PayloadAction<{ solutionFeatures: Partial<SolutionFeatures> }>
    ) => {
      Object.entries(payload.solutionFeatures).forEach(
        ([featureName, featureValue]) => {
          // Only set the feature value if it is a known feature in the state
          if (Object.keys(state.solutionFeatures).includes(featureName)) {
            state.solutionFeatures[featureName as SolutionFeatureName] =
              featureValue;
          }
        }
      );
    },

    resetFeatureToggles: (state) => ({
      ...state,
      featureToggles: { ...initialState.featureToggles },
    }),

    resetRolloutFlags: (state) => ({
      ...state,
      rolloutFlags: { ...initialState.rolloutFlags },
    }),

    resetSolutionFeatures: (state) => ({
      ...state,
      solutionFeatures: { ...initialState.solutionFeatures },
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  setFeatureToggles,
  setRolloutFlags,
  setSolutionFeatures,
  resetFeatureToggles,
  resetRolloutFlags,
  resetSolutionFeatures,
} = featuresSlice.actions;

// Helper function that creates a selector for a flag
export const createFeatureToggleSelector =
  (name: FeatureToggleName) =>
  (state: Pick<RootState, 'features'>): boolean =>
    state.features.featureToggles[name];

// Helper function that creates a selector for a rollout flag
export const createRolloutFlagSelector =
  (name: RolloutFlagName) =>
  (state: Pick<RootState, 'features'>): boolean =>
    state.features.rolloutFlags[name];

// Helper function that creates a selector for a solution feature
export const createSolutionFeatureSelector =
  (name: SolutionFeatureName) =>
  (state: Pick<RootState, 'features'>): boolean =>
    state.features.solutionFeatures[name];

export const getAllFeatureToggles = (state: Pick<RootState, 'features'>) =>
  state.features.featureToggles;

export const getAllRolloutFlags = (state: Pick<RootState, 'features'>) =>
  state.features.rolloutFlags;

export const getAllSolutionFeatures = (state: Pick<RootState, 'features'>) =>
  state.features.solutionFeatures;

export default featuresSlice.reducer;
